import { AuthOptions, WebAuth } from 'auth0-js';
import { REACT_APP_AUTH0_DOMAIN, REACT_APP_AUTH0_VERIFY_PHONE_NUMBER_CLIENT_ID } from '../config';
import { addCountryCode } from '../utils/helperFunctions';

const verifyPhoneNumberOptions: AuthOptions = {
  domain: REACT_APP_AUTH0_DOMAIN || '',
  clientID: REACT_APP_AUTH0_VERIFY_PHONE_NUMBER_CLIENT_ID || '',
  responseType: 'token id_token'
};

const auth0UpdateEmailWebAuth = new WebAuth(verifyPhoneNumberOptions);

const loginSms = async (phoneNumber: string) => {
  const data = {
    phoneNumber: `${addCountryCode(phoneNumber)}`,
    send: 'code',
    connection: 'sms',
    authParams: {
      scope: 'openid'
    }
  };

  await new Promise((resolve, reject) => {
    auth0UpdateEmailWebAuth.passwordlessStart(data, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response);
      }
    });
  });
};

const verifyPhoneNumber = async (phoneNumber: string, code: string, userId: string) => {
  const response = await fetch(`https://${REACT_APP_AUTH0_DOMAIN}/oauth/token`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json;charset=UTF-8' },
    body: JSON.stringify({
      // Sonarcloud throws an error when it encounters a reference to a non-secure HTTP. Non-secure address required by Auth0
      // NOSONAR
      grant_type: 'http://auth0.com/oauth/grant-type/passwordless/otp',
      client_id: REACT_APP_AUTH0_VERIFY_PHONE_NUMBER_CLIENT_ID,
      username: phoneNumber,
      otp: code,
      realm: 'sms',
      scope: 'openid',
      metadata: JSON.stringify({
        id: userId
      })
    })
  });

  if (!response.ok) {
    throw await response.json();
  }
};

export { loginSms, verifyPhoneNumber };
