import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { authSelector } from '../../features/auth/authSlice';

import { REACT_APP_AUTH0_CONTINUE_URL } from '../../config';
import { LoadSpinner } from '../../components/LoadSpinner';

const Continue = () => {
  const { authState, continueToken } = useSelector(authSelector);

  useEffect(() => {
    window.location.href = `${REACT_APP_AUTH0_CONTINUE_URL}?state=${authState}&token=${continueToken}`;
  }, [authState, continueToken]);

  return <LoadSpinner />;
};

export default Continue;
