import * as Yup from 'yup';
import { subYears } from 'date-fns';

export const nameRegex = /^[a-zA-ZÀ-ž]+[ '\-.]?[a-zA-ZÀ-ž]+$/;

export const personaDetailsValidationSchema = Yup.object({
  firstName: Yup.string()
    .matches(nameRegex, 'Invalid first name entered')
    .required('Enter your first name')
    .min(2, 'Must be 2 characters or more')
    .max(50, 'Must be 50 characters or less'),
  lastName: Yup.string()
    .matches(nameRegex, 'Invalid last name entered')
    .required('Enter your last name')
    .min(2, 'Must be 2 characters or more')
    .max(50, 'Must be 50 characters or less'),
  dateOfBirth: Yup.date()
    .required('Enter your date of birth')
    .nullable()
    .max(subYears(new Date(), 18), 'You have to be 18 years or older to register')
    .typeError('Invalid date'),
  postcode: Yup.string()
    .max(20, 'Must be 20 characters or less')
    .test('postcode-regex', function (value: any) {
      const regex = new RegExp(getPostCodeRegexByCountry(this.parent.country));
      return !regex.test(value)
        ? this.createError({
            message: `${'Enter a valid UK post code'}`,
            path: 'postcode'
          })
        : true;
    }),
  termsVersion: Yup.string().required('Please accept our Terms & Conditions to continue'),
  country: Yup.string().required('Country is Required')
});

export const emailValidationSchema = Yup.object({
  email: Yup.string()
    .email()
    .required('This email address is not valid')
    .max(100, 'Must be 50 characters or less')
});

export const mobileNumberValidationSchema = Yup.object({
  mobile: Yup.string().test('mobile-regex', function (value: any) {
    const regex = new RegExp(mobileRegEx);
    return !regex.test(value)
      ? this.createError({
          message: `${'Please provide a valid UK mobile number'}`,
          path: 'mobile'
        })
      : true;
  })
});

export const codeValidationSchema = Yup.object({
  code: Yup.string()
    .required()
    .matches(/^[0-9]+$/, 'Must be only digits')
    .min(6, 'Must be exactly 6 digits')
    .max(6, 'Must be exactly 6 digits')
});

export const getPostCodeRegexByCountry = (country?: string) => {
  return /^([A-Za-z][A-Ha-hJ-Yj-y]?[0-9][A-Za-z0-9]? ?[0-9][A-Za-z]{2}|[Gg][Ii][Rr] ?0[Aa]{2})$/;
};

export const mobileRegEx = /^((0)7([12345789]\d{2}|624)\s?\d{3}\s?\d{3})$/;
