import CookieConsent from 'react-cookie-consent';
import { useDispatch } from 'react-redux';
import { showCookieModal } from '../../features/cookie/cookieSlice';
import { color, FontFamilies, media } from '../../styles';
import { setAllCookiePreferences } from './cookiePreferences';

const dotcom = process.env.REACT_APP_T_AND_C_BASE_URL;

function CookieBar() {
  const dispatch = useDispatch();

  return (
    <>
      <style jsx>{`
        .cookie-btn-wrapper {
          display: flex;
          justify-content: center;
          margin-top: 5vmin;
        }

        .cookie-btn-confirm {
          background-color: ${color.brandPrimary};
          border: 1px solid ${color.brandPrimary};
          border-radius: 8px;
          color: ${color.white};
          cursor: pointer;
          display: block;
          font-family: Barlow, sans-serif;
          font-size: 15px;
          font-weight: bold;
          height: 48px;
          line-height: 1.5;
          min-width: fit-content;
          padding: 0px 16px;
          text-align: center;
          transition: all 150ms ease-out 0s;
          width: 149px;
          margin-right: 20px;
        }

        .cookie-btn-confirm:hover {
          background-color: ${color.brandPrimary};
          border: 1px solid ${color.brandPrimary};
          border-radius: 8px;
          color: ${color.white};
        }

        .cookie-btn-decline {
          background-color: ${color.white};
          border: 1px solid ${color.lightGrey};
          border-radius: 8px;
          color: ${color.brandPrimary};
          cursor: pointer;
          display: block;
          font-family: Barlow, sans-serif;
          font-size: 15px;
          font-weight: bold;
          height: 48px;
          line-height: 1.5;
          min-width: fit-content;
          padding: 0px 16px;
          text-align: center;
          transition: all 150ms ease-out 0s;
          width: 149px;
        }

        .cookie-btn-decline:hover {
          background-color: ${color.white};
          border: 1px solid ${color.lightGrey};
          border-radius: 8px;
          color: ${color.brandPrimary};
        }
        .cookie-container {
          background-color: ${color.white};
          border-radius: 4px;
          box-shadow: rgb(0 0 0 / 14%) 0px 24px 38px 3px, rgb(0 0 0 / 12%) 0px 9px 46px 8px,
            rgb(0 0 0 / 20%) 0px 11px 15px -7px;
          flex-shrink: 0;
          padding: 45px 12px;
          position: absolute;
          bottom: 0px;
          width: 100%;
          left: 0px;
          position: fixed;
          width: 100%;
          z-index: 999;
        }

        .cookie-title {
          top: 45px;
          font-family: ${FontFamilies.barlow};
          font-weight: 700;
          font-size: 22px;
          line-height: 26px;
          color: ${color.black};
          margin: 0 10vmin 0 20vmin;
        }

        .cookie-subtitle {
          margin: 0 10vmin 0 20vmin;
          font-family: ${FontFamilies.barlow};
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 5vmin;
          color: ${color.black};
        }

        .cookie-subtitle a {
          color: ${color.brandPrimary};
          font-weight: 600;
          text-decoration: none;
        }

        @media ${media.mobileAndLower} {
          .cookie-title {
            margin: 0 10vmin 0 10vmin;
          }
          .cookie-subtitle {
            margin: 0 10vmin 0 10vmin;
          }
        }
      `}</style>

      <CookieConsent
        disableStyles
        enableDeclineButton
        flipButtons
        cookieName={'virgin-id'}
        expires={365}
        location="bottom"
        buttonWrapperClasses="cookie-btn-wrapper"
        buttonClasses="cookie-btn-confirm"
        declineButtonClasses="cookie-btn-decline"
        buttonText="Accept cookies"
        declineButtonText="Customise"
        containerClasses="cookie-container"
        onDecline={() => dispatch(showCookieModal())}
        onAccept={() => setAllCookiePreferences()}
      >
        <p className="cookie-title">We use cookies to improve your experience with Virgin ID</p>
        <p className="cookie-subtitle">
          You can read our full{' '}
          <a href={`${dotcom}/privacy-policy`} rel="noreferrer" target="_blank">
            Privacy Policy
          </a>{' '}
          and{' '}
          <a rel="noreferrer" href={`${dotcom}/cookie-policy`} target="_blank">
            Cookie Policy
          </a>{' '}
          which explains how we use cookies.
        </p>
      </CookieConsent>
    </>
  );
}

export default CookieBar;
