import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { authSelector } from '../../features/auth/authSlice';
import { getProfile, profileSelector } from '../../features/profile/profileSlice';
import { PATHS } from '../../routes/paths';
import { GridContainer, GridItem, GridWrapper } from '@virgin-core/components';
import { color, FontFamilies } from '../../styles';
import Button from '@material-ui/core/Button/Button';
import virginTrainsLogo from '../../../src/virgin-trains.png';
import virginRedLogo from '../../../src/virgin-red.png';

const data = {
  clients: {
    vtt: {
      logo: virginTrainsLogo,
      name: 'Virgin Trains Ticketing'
    },
    vred: {
      logo: virginRedLogo,
      name: 'Virgin Red'
    }
  }
};

const Consent = () => {
  const { accessToken, externalId } = useSelector(authSelector);
  const profileState = useSelector(profileSelector);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (accessToken) {
      dispatch(getProfile(accessToken, externalId));
    }
  }, [accessToken, externalId, dispatch]);

  useEffect(() => {
    if (profileState?.profile?.complete) navigate(PATHS.CONTINUE);
  }, [profileState, navigate]);

  const handleAccept = () => {
    alert('Consent accepted');
  };

  const handleDeny = () => {
    alert('Consent denied');
  };

  return profileState.loading ? (
    <>
      Loading...
      {!accessToken && (
        <>No accessToken available to app - restart app flow via /?token=[your.lovely.token]</>
      )}
    </>
  ) : (
    <>
      {accessToken ? (
        <>
          <style jsx>{`
            h3 {
              font-family: ${FontFamilies.barlow};
              font-size: 20px;
              font-weight: 600;
              line-height: 1.5;
              margin: 0;
            }

            .section-header {
              margin-bottom: 16px;
            }

            .title {
              color: ${color.brandPrimary};
              font-family: ${FontFamilies.barlow};
              font-weight: 600;
              font-style: italic;
              font-size: 28px;
              line-height: 32px;
            }

            .step-title {
              color: ${color.grey07};
              font-family: ${FontFamilies.barlow};
              font-weight: 400;
              font-size: 14px;
              line-height: 48px;
              margin-top: 50px;
            }

            .details-header {
              justify-content: flex-start;
              margin-bottom: 32px;
              font-family: ${FontFamilies.barlow};
            }

            .details-header .grid-item {
              padding: 0 !important;
            }

            .details-header span {
              text-decoration: underline;
            }

            .MuiTextField-root .MuiFormLabel-root.Mui-error,
            .MuiTextField-root .MuiFormLabel-root.Mui-error.Mui-focused {
              color: ${color.redHover} !important;
            }
            [class*='MuiFormLabel-root-'].Mui-error,
            [class*='MuiFormLabel-root-'].Mui-error.Mui-focused {
              color: ${color.redHover} !important;
            }
            .MuiTextField-root .MuiFormHelperText-root.Mui-error {
              color: ${color.redHover} !important;
            }
            [class*='MuiFormHelperText-root-'].Mui-error {
              color: ${color.redHover} !important;
            }
            .MuiTextField-root .MuiInputBase-root.Mui-error:after {
              border-bottom-color: ${color.redHover} !important;
            }
            [class*='MuiInputBase-root-'].Mui-error:after {
              border-bottom-color: ${color.redHover} !important;
            }
            .MuiTextField-root .MuiInputBase-root:before {
              border-bottom-color: ${color.lighterGrey};
              border-width: 2px;
            }
            [class*='MuiInputBase-root-']:before {
              border-bottom-color: ${color.lighterGrey};
              border-width: 2px;
            }
            .MuiTextField-root .MuiInputBase-root:hover:not(.Mui-disabled):before {
              border-bottom-color: ${color.textStandard} !important;
            }
            [class*='MuiInputBase-root-']:hover:not(.Mui-disabled):before {
              border-bottom-color: ${color.textStandard} !important;
            }
            .MuiTextField-root .MuiInputBase-root:after,
            .MuiTextField-root .MuiInputBase-root.Mui-focused:after {
              border-bottom-color: ${color.textStandard};
            }
            [class*='MuiInputBase-root-']:after,
            [class*='MuiInputBase-root-'].Mui-focused:after {
              border-bottom-color: ${color.textStandard};
            }
            .MuiTextField-root MuiFormLabel-root,
            .MuiTextField-root MuiFormLabel-root.Mui-focused {
              font-family: ${FontFamilies.barlow} !important;
              color: ${color.lighterGrey} !important;
            }
            [class*='MuiFormLabel-root-'],
            [class*='MuiFormLabel-root-'].Mui-focused {
              font-family: ${FontFamilies.barlow} !important;
              color: ${color.lighterGrey} !important;
            }
            .MuiTextField-root *,
            .checkbox-wrapper .MuiFormControlLabel-label,
            .MuiPickersModal-dialogRoot * {
              font-family: ${FontFamilies.barlow} !important;
              letter-spacing: initial !important;
            }
            [class*='MuiTextField-root-'] *,
            .checkbox-wrapper [class*='MuiFormControlLabel-label-'],
            [class*='MuiPickersModal-dialogRoot-'] * {
              font-family: ${FontFamilies.barlow} !important;
              letter-spacing: initial !important;
            }
            .MuiListItem-root .MuiTouchRipple-child {
              background-color: transparent;
            }
            [class*='MuiListItem-root-'] [class*='MuiTouchRipple-child-'] {
              background-color: transparent;
            }

            .input-field,
            .dob-field {
              min-height: 72px;
            }

            .dob-field {
              margin-bottom: 16px;
            }

            .dob-field .MuiIconButton-root {
              padding: 0;
            }

            .marketing-pref-item {
              padding: 12px 0 !important;
            }

            .dob-note {
              color: ${color.lighterGrey};
              font-size: 14px;
              font-family: ${FontFamilies.barlow};
            }

            .comm-block {
              padding: 12px 0;
              margin-top: 16px;
              font-family: ${FontFamilies.barlow};
            }

            .checkbox-wrapper,
            .checkbox-terms-wrapper {
              display: flex;
              flex-direction: row-reverse;
              justify-content: flex-end;
              align-items: flex-start;
              font-family: ${FontFamilies.barlow};
            }

            .checkbox-terms-wrapper {
              margin-bottom: 24px;
            }

            .MuiTextField-root .MuiInputLabel-shrink {
              transform: translate(0, 1.5px) scale(0.86);
              font-family: ${FontFamilies.barlow};
              color: ${color.lighterGrey};
            }

            [class*='MuiInputLabel-shrink-'] {
              transform: translate(0, 1.5px) scale(0.86);
              font-family: ${FontFamilies.barlow};
              color: ${color.lighterGrey};
            }

            .MuiListItem-root {
              display: block;
              font-weight: 600;
              font-family: ${FontFamilies.barlow} !important;
            }

            [class*='MuiListItem-root-'] {
              display: block;
              font-weight: 600;
              font-family: ${FontFamilies.barlow} !important;
            }

            .MuiTooltip-tooltip {
              font-size: 0.875rem;
            }

            [class*='MuiTooltip-tooltip-'] {
              font-size: 0.875rem;
            }

            MuiListItem-root:hover,
            .MuiListItem-root.Mui-selected {
              background-color: transparent !important;
              color: ${color.brandPrimary};
              transition: all 250ms ease 0s;
            }

            [class*='MuiListItem-root-']:hover,
            [class*='MuiListItem-root-'].Mui-selected {
              background-color: transparent !important;
              color: ${color.brandPrimary};
              transition: all 250ms ease 0s;
            }

            .MuiPickersModal-dialogRoot .MuiPickersToolbar-toolbar,
            .MuiPickersModal-dialogRoot .MuiPickersDay-daySelected {
              font-family: ${FontFamilies.barlow} !important;
              background-color: ${color.brandPrimary} !important;
            }
            .MuiPickersModal-dialogRoot .MuiButton-textPrimary,
            .MuiPickersModal-dialogRoot .MuiPickersYear-yearSelected,
            .MuiPickersModal-dialogRoot .MuiPickersYear-root:focus {
              font-family: ${FontFamilies.barlow} !important;
              color: ${color.brandPrimary} !important;
            }

            .postcode-field .MuiIconButton-root {
              color: ${color.lightGrey} !important;
              background-image: radial-gradient(circle at 50% 50%, black 25%, transparent 25%);
            }

            .postcode-field [class*='MuiIconButton-root-'] {
              color: ${color.lightGrey} !important;
              background-image: radial-gradient(circle at 50% 50%, black 25%, transparent 25%);
            }

            .MuiButtonBase-root.MuiCheckbox-root {
              color: ${color.lightGrey};
              padding: 0 8px 0 0;
            }
            .MuiButtonBase-root.MuiCheckbox-root.Mui-checked {
              color: ${color.brandPrimary};
            }

            .button-container .MuiButton-label {
              min-width: 109px;
            }

            .MuiButton-root {
              letter-spacing: normal;
            }

            .MuiIconButton-colorSecondary:hover {
              background-color: transparent !important;
            }

            .MuiTouchRipple-root {
              visibilty: none !important;
              color: transparent !important;
            }

            .terms-section {
              margin: 24px 0;
            }

            .terms-error {
              position: relative;
              background-color: ${color.darkPinkBackground};
              padding: 12px 16px 12px 52px;
              box-sizing: border-box;
            }
            .terms-error:before {
              position: absolute;
              top: 14px;
              left: 19px;
              display: block;
              background-color: ${color.brandPrimary};
              border-radius: 100%;
              content: '!';
              font-size: 14px;
              font-weight: 600;
              color: ${color.white};
              text-align: center;
              width: 20px;
              height: 20px;
              line-height: 1.3;
            }

            .instructions {
              font-family: ${FontFamilies.barlow};
              line-height: 1.5;
            }

            .button-container {
              padding: 24px 0 10px;
            }

            .button-container .grid-item {
              padding: 0 !important;
            }

            .submit-button {
              text-transform: none;
              font-family: ${FontFamilies.barlow};
              font-size: 15px;
              font-weight: bold;
              border: 1px solid rgb(225, 10, 10);
              border-radius: 8px;
              color: ${color.white};
              background-color: ${color.brandPrimary};
              min-width: 140px;
              height: 48px;
              cursor: pointer;
              line-height: 1.5;
              padding: 0px 16px;
              text-align: center;
              transition: all 150ms ease-out 0s;
              width: 100%;
            }

            .submit-button:hover {
              background-color: ${color.brandPrimary} !important;
            }

            .deny-button {
              text-transform: none;
              font-family: ${FontFamilies.barlow};
              font-size: 15px;
              font-weight: bold;
              color: ${color.black};
              min-width: 140px;
              height: 48px;
              cursor: pointer;
              line-height: 1.5;
              padding: 0px 5px;
              text-align: center;
              transition: all 150ms ease-out 0s;
              width: 100%;
            }
          `}</style>

          <section className="terms-section">
            <div className="parent-container">
              <GridWrapper expanded>
                <GridContainer className="top-bar-nav">
                  <GridItem xs={4} className="logo-container">
                    <img src={data.clients.vtt.logo} width="200px" alt={data.clients.vtt.name} />
                  </GridItem>
                </GridContainer>
              </GridWrapper>
            </div>
            <p className="instructions">
              To get you signed in smoothly {data.clients.vtt.name} requests some of the personal
              details from your Virgin ID.
              <br />
              <br />
              Would you like to share these details with {data.clients.vtt.name}?
            </p>
          </section>
          <div className="button-container">
            <Button
              data-testid="onboarding-personal-details-button"
              className="submit-button"
              type="submit"
              onClick={() => handleAccept()}
            >
              Share with {data.clients.vtt.name}
            </Button>

            <Button
              data-testid="onboarding-personal-details-button"
              className="deny-button"
              type="submit"
              onClick={() => handleDeny()}
            >
              Don't Share my details
            </Button>
          </div>
        </>
      ) : (
        <>Something went wrong. Please try logging in again.</>
      )}
    </>
  );
};

export default Consent;
