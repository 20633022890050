import React, { Component, FC } from 'react';

import { ZIndex, color, media } from '../../styles';

import {
  CookiePreferenceId,
  CookiePreferences,
  getCookiePreferences,
  getDefaultCookiePreferences,
  setCookiePreferences
} from './cookiePreferences';
import { cookieSelector } from '../../features/cookie/cookieSlice';

import Cross from './Cross';
import Button from '@material-ui/core/Button/Button';

import CookieTracker from './CookieTracker';
import { cookieDataMap, CookiePreferenceData, necessaryCookiesData } from './cookiePreferencesData';
import ModalHeader from './ModalHeader';
import ModalBody from './ModalBody';
import { FontFamilies } from '@virgin-core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { hideCookieModal, showCookieBanner } from '../../features/cookie/cookieSlice';

const dotcom = process.env.REACT_APP_T_AND_C_BASE_URL;

interface CookieModalProps {
  close: (cookiesAccepted: boolean) => void;
}

interface CookieModalState {
  cookiePreferences: CookiePreferences;
}

export class CookieModal extends Component<CookieModalProps, CookieModalState> {
  constructor(props: CookieModalProps) {
    super(props);
    this.state = {
      cookiePreferences: getCookiePreferences() ?? getDefaultCookiePreferences()
    };
  }

  toggleCookiePreference = (cookiePreferenceId: CookiePreferenceId): void => {
    const newCookiePreferences = { ...this.state.cookiePreferences };
    newCookiePreferences[cookiePreferenceId] = !newCookiePreferences[cookiePreferenceId];
    this.setState({ cookiePreferences: newCookiePreferences });
  };

  saveAndClose = (): void => {
    const { close } = this.props;
    setCookiePreferences(this.state.cookiePreferences);
    close(true);
  };

  render() {
    const { close } = this.props;
    const { cookiePreferences } = this.state;

    return (
      <>
        <style jsx>{`
          .cookie-modal {
            background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
            font-family: ${FontFamilies.barlow}
            display: flex;
            flex-direction: column;
            height: 100%;
            left: 0;
            overflow-y: auto;
            padding: 20px 0;
            position: fixed;
            top: 0;
            width: 100%;
            z-index: ${ZIndex.Modal};
          }
          .cookie-modal-box {
            background-color: #fefefe;
            border: 1px solid #888;
            flex-shrink: 0;
            margin: auto;
            max-width: 100%;
            position: relative;
            width: 880px;
            font-family: ${FontFamilies.barlow}
          }
          .close {
            cursor: pointer;
            padding: 20px;
            position: absolute;
            right: 20px;
            top: 30px;
          }
          .cookie-modal-header {
            padding: 40px 0 24px;
          }
          .cookie-modal-content {
            padding: 0 100px;
          }
          .cookie-modal-text {
            margin-bottom: 40px;
            font-family: ${FontFamilies.barlow};
            text-align: justify;
          }
          .cookie-modal-bottom {
            margin: 0 auto;
            padding: 40px 0;
            width: 200px;
          }
          @media ${media.tabletAndLower} {
            .cookie-modal-box {
              width: 600px;
            }
            .cookie-modal-content {
              padding: 0 93px;
            }
            .cookie-modal-text {
              margin-bottom: 32px;
            }
            .cookie-modal-bottom {
              padding: 32px 0;
              width: 165px;
            }
          }
          @media ${media.mobileAndLower} {
            .cookie-modal {
              padding: 0;
            }
            .cookie-modal-box {
              display: flex;
              flex-direction: column;
              flex-grow: 1;
              width: auto;
            }
            .cookie-modal-content {
              flex-grow: 1;
              flex-shrink: 0;
              padding: 0 24px;
            }
            .close {
              right: 0;
              top: 0;
            }
            .cookie-modal-header {
              padding: 24px 0 16px;
            }
            .cookie-modal-text {
              margin-bottom: 24px;
            }
            .cookie-modal-bottom {
              padding: 16px 15px 24px;
              width: 100%;
            }
          }
        `}</style>
        <div className="cookie-modal">
          <div className="cookie-modal-box">
            <div className="cookie-modal-header">
              <ModalHeader>Cookie preferences</ModalHeader>
              <div className="close" onClick={(): void => close(false)} data-testid="close-cross">
                <Cross size={18} color={color.black} />
              </div>
            </div>
            <div className="cookie-modal-content">
              <div className="cookie-modal-text">
                <ModalBody>
                  We use cookies (yum!) and other technology to track how you interact with us, our
                  partners and to understand you. They enable us to personalise your experience and
                  provide you with more relevant content based on things you’ve shown an interest in
                  or we think you might like. They also help us keep this site running smoothly. You
                  can change these settings at any time on the{' '}
                  <a href={`${dotcom}/cookie-policy`} target="_blank" rel="noreferrer">
                    cookie policy page
                  </a>
                  .
                </ModalBody>
              </div>
              <CookieTracker
                title={necessaryCookiesData.title}
                content={necessaryCookiesData.content}
                isSelected={true}
                toggleCookiePreference={null}
              />
              {Object.entries<CookiePreferenceData>(cookieDataMap).map(
                ([key, data]: [string, CookiePreferenceData]) => {
                  const id = key as CookiePreferenceId;
                  return (
                    <CookieTracker
                      key={id}
                      title={data.title}
                      content={data.content}
                      toggleCookiePreference={(): void => this.toggleCookiePreference(id)}
                      isSelected={cookiePreferences[id]}
                    />
                  );
                }
              )}
            </div>
            <div className="cookie-modal-bottom">
              <Button
                data-testid="connect-account"
                className="cookie-btn-confirm"
                type="submit"
                onClick={this.saveAndClose}
              >
                Save and close
              </Button>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const CookieModalWrapper: FC = ({ ...props }) => {
  const dispatch = useDispatch();
  const isCookieModalVisible = useSelector(cookieSelector).showModal;

  if (!isCookieModalVisible) return null;

  return (
    <CookieModal
      close={(cookiesAccepted): void => {
        dispatch(hideCookieModal());
        dispatch(showCookieBanner());
      }}
      {...props}
    />
  );
};

export default CookieModalWrapper;
