export const {
  REACT_APP_ENV,
  REACT_APP_IS_PRODUCTION,
  REACT_APP_API_URL,
  REACT_APP_AUTH0_CONTINUE_URL,
  REACT_APP_DOTCOM_URL,
  REACT_APP_COOKIE_DOMAIN,
  REACT_APP_AUTH0_VERIFY_PHONE_NUMBER_CLIENT_ID,
  REACT_APP_AUTH0_DOMAIN
} = process.env;
