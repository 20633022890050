import React from 'react';
import { media, FontFamilies, color } from '../../styles';
import ModalBody from './ModalBody';

import ToggleSwitch from './ToggleSwitch';

export interface CookieTrackerProps {
  title: string;
  content: string;
  isSelected: boolean;
  toggleCookiePreference: null | (() => void);
  isLast?: boolean;
}

function CookieTracker({
  title,
  content,
  isSelected,
  toggleCookiePreference,
  isLast
}: CookieTrackerProps) {
  return (
    <>
      <style jsx>{`
        .cookie-tracker {
          ${!isLast && 'margin-bottom: 16px;'}
        }
        .top-section {
          display: flex;
          justify-content: space-between;
          margin-bottom: -14px;
        }
        .toggle-switch {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 2px;
        }

        @media ${media.tabletAndHigher} {
          .cookie-tracker {
            ${!isLast && 'margin-bottom: 24px;'}
          }
        }

        .body {
          text-align: left;
          font-family: ${FontFamilies.barlow};
          font-size: 28px;
          line-height: 1.5;
          color: ${color.textStandard};
          padding: 0;
          font-weight: bold;
        }
        @media ${media.tabletAndHigher} {
          .body {
            font-size: 14px;
          }
        }

        @media ${media.desktopAndHigher} {
          .body {
            font-size: 20px;
          }
        }
      `}</style>
      <div className="cookie-tracker">
        <div className="top-section">
          <p className="body">{title}</p>
          <div className="toggle-switch">
            <ToggleSwitch isSelected={isSelected} toggleCookiePreference={toggleCookiePreference} />
          </div>
        </div>
        <ModalBody>{content}</ModalBody>
      </div>
    </>
  );
}

export default CookieTracker;
