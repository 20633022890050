import React from 'react';

import { DOTCOM_PATHS } from '../../routes/paths';
import { media } from '../../styles';
import VirginIdentityLogo from '../VirginIdentityLogo';
import { color } from '../../styles';
import { GridWrapper, GridContainer, GridItem } from '@virgin-core/components';

interface TopBarNavProps {
  redirectHome?: () => void;
  activeSectionSelected?: () => void;
  activeParent?: string;
  linkCentreLogo?: boolean;
}

const TopBarNav = ({ linkCentreLogo = true }: TopBarNavProps) => {
  return (
    <>
      <style jsx>{`
        .parent-container {
        }
        .top-bar-nav {
          align-items: center;
        }
        :global(.top-bar-nav) {
          position: relative;
          background: ${color.white};
          height: 64px;
        }
        .logo-container {
          display: flex;
          justify-content: center;
        }
        .cancel-button-container {
          display: flex;
          justify-content: flex-end;
        }

        .cancel-button-container a {
          text-decoration: none;
        }

        :global(.logo-container) {
          text-align: center;
        }
        .active-section {
          display: none;
        }
        @media ${media.tabletLargeAndHigher} {
          .active-section {
            display: block;
          }
        }
      `}</style>
      <div className="parent-container">
        <GridWrapper expanded>
          <GridContainer className="top-bar-nav">
            <GridItem xs={4} className="logo-container">
              {linkCentreLogo ? (
                <a href={DOTCOM_PATHS.HOME}>
                  <VirginIdentityLogo />
                </a>
              ) : (
                <VirginIdentityLogo />
              )}
            </GridItem>
          </GridContainer>
        </GridWrapper>
      </div>
    </>
  );
};

export default TopBarNav;
