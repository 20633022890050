import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../..';
import { loginSms, verifyPhoneNumber } from '../../dataImplementation/auth';
import { addCountryCode } from '../../utils/helperFunctions';

export interface PhoneState {
  errorsPhone: any;
  smsSent: boolean;
  mobileNumber: string;
  codeValid: boolean;
  errorsCode: any;
}

const initialState: PhoneState = {
  errorsPhone: null,
  smsSent: false,
  mobileNumber: '',
  codeValid: false,
  errorsCode: null
};

const phoneSlice = createSlice({
  name: 'phone',
  initialState,
  reducers: {
    setErrorsPhone: (state, { payload }: PayloadAction<any>) => {
      state.errorsPhone = payload;
    },
    setPhoneRes: (state, { payload }: PayloadAction<boolean>) => {
      state.smsSent = payload;
    },
    setMobileNumber: (state, { payload }: PayloadAction<string>) => {
      state.mobileNumber = addCountryCode(payload);
    },
    setCodeValid: (state, { payload }: PayloadAction<boolean>) => {
      state.codeValid = payload;
    },
    setErrorsCode: (state, { payload }: PayloadAction<any>) => {
      state.errorsCode = payload;
    }
  }
});

export const sendSmsToPhone = (phoneNumber: string): AppThunk => {
  return async (dispatch) => {
    dispatch(setMobileNumber(phoneNumber));
    try {
      await loginSms(phoneNumber);
      dispatch(setPhoneRes(true));
    } catch (error) {
      dispatch(setErrorsPhone(error));
    }
  };
};

export const verifyCode = (phoneNumber: string, code: string, userId: string): AppThunk => {
  return async (dispatch) => {
    try {
      await verifyPhoneNumber(phoneNumber, code, userId);
      dispatch(setCodeValid(true));
    } catch (error) {
      dispatch(setErrorsCode(error));
    }
  };
};

// actions
export const { setErrorsPhone, setPhoneRes, setMobileNumber, setCodeValid, setErrorsCode } =
  phoneSlice.actions;

export default phoneSlice.reducer;

export const phoneSelector = (state: { phone: PhoneState }) => state.phone;
