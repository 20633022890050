import React from 'react';
import { color } from '../../styles';

interface CrossProps {
  size: number;
  color: color;
  className?: string;
  onClick?: Function | any;
}

function Cross({ size, color, ...rest }: CrossProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 14 14"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g id="---components" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="raap-icons-ui-elements-001" transform="translate(-980.000000, -565.000000)">
          <g id="ui/icon/small/cross" transform="translate(979.000000, 564.000000)">
            <rect id="Rectangle" x="0" y="0" width="16" height="16" />
            <mask id="mask-2" fill="white">
              <path d="M7.99718606,5.7569913 L12.6041937,1.16222212 C12.7068677,1.05676661 12.8484373,0.998150018 12.9956737,1.00008196 C13.1402745,1.00008196 13.2778215,1.05298651 13.3862721,1.16222212 L14.8322794,2.60695115 C14.939814,2.70885464 15.0004946,2.85057694 14.999997,2.99865567 C14.9994932,3.14673441 14.9378555,3.28804384 14.8296343,3.38921906 L10.2006473,8.00530451 L10.1997656,8.00354265 L14.8199355,12.6108188 C14.9254789,12.7127342 14.9850792,12.8530995 14.9850792,12.9997504 C14.9850792,13.1464013 14.9254789,13.2867666 14.8199355,13.388682 L13.3739281,14.8334111 C13.271676,14.9396912 13.1299877,14.9989946 12.9824481,14.9973013 C12.8364036,14.9975391 12.6964638,14.9387528 12.5944949,14.834292 L7.99918674,10.2474356 L3.38766021,14.8360539 C3.28766109,14.9424165 3.1475149,15.0018781 3.00147044,14.9999547 C2.85349957,14.9976885 2.71255333,14.9364755 2.6099904,14.8298873 L1.16398304,13.3851583 C1.05748564,13.2831513 0.998372123,13.1414043 1.00086636,12.9940243 C1.00086636,12.841623 1.05553249,12.7147688 1.1683916,12.6028904 L5.78591632,7.99209053 L5.78591632,8.00354265 L5.78679803,8.00354265 L1.16662818,3.3874572 C1.05992589,3.28606023 0.999669204,3.14527397 1.00000137,2.9981465 C1.00033628,2.85101904 1.06123067,2.71050694 1.1683916,2.60959395 L2.61439896,1.16310305 C2.71547158,1.05733491 2.85599975,0.998301438 3.00235215,1.00006341 C3.14914099,0.99779434 3.29030089,1.05650579 3.39206877,1.16222212 L7.99718606,5.7569913 Z" />
            </mask>
            <use
              id="Mask"
              fill={color}
              fillRule="evenodd"
              transform="translate(8.000000, 8.000000) rotate(-90.000000) translate(-8.000000, -8.000000) "
              xlinkHref="#path-1"
            />
            <g id="--swatch/brand/default" mask="url(#mask-2)" fill={color} fillRule="evenodd">
              <g
                transform="translate(8.020815, 8.020815) rotate(-360.000000) translate(-8.020815, -8.020815) translate(0.020815, 0.020815)"
                id="Rectangle"
              >
                <rect x="0" y="0" width="16" height="16.0969697" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Cross;
