import React from 'react';
import { AppRoutes } from './routes/routes';
import './App.css';
import { BrowserRouter } from 'react-router-dom';
import { GridWrapper, GridContainer, GridItem } from '@virgin-core/components';
import TopBarNav from './components/TopBarNav';
import CookieBar from './components/Cookie/CookieBar';
import CookieModalWrapper from './components/Cookie/CookieModal';

const App: React.FC = () => {
  return (
    <div className="App">
      <header className="App-header">
        <TopBarNav linkCentreLogo={true} />
        <GridWrapper>
          <GridContainer>
            <GridItem xs={12} sm={8} className="card-wrapper">
              <div className="card">
                <BrowserRouter>
                  <AppRoutes />
                </BrowserRouter>
              </div>
            </GridItem>
          </GridContainer>
        </GridWrapper>

        <CookieBar />
        <CookieModalWrapper />
      </header>
    </div>
  );
};

export default App;
