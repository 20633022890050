import { REACT_APP_DOTCOM_URL } from '../config';

export const PATHS = {
  OOPS: '/oops',
  INIT: '/',
  CONTINUE: '/continue',
  GUARD: '/profile-check',
  EMAIL_CHECK: '/email-check',
  MOBILE_NUMBER: '/mobile-number',
  MOBILE_VERIFY: '/mobile-verify',
  PERSONAL_DETAILS: '/personal-details',
  CONSENT: '/consent',
  TERMS_AND_CONDITIONS: `/terms-and-conditions`,
  PRIVACY: `/privacy-policy`
};

export const DOTCOM_PATHS = {
  HOME: `${REACT_APP_DOTCOM_URL}`
};
