import React from 'react';

import { color, FontFamilies } from '../../styles';
import { GridContainer, GridItem } from '@virgin-core/components';
import StyledCopyAnchor from '../StyledCopyAnchor';

interface HelpContainerProps {
  text?: string;
  linkText?: string;
  linkURL?: string;
  target?: boolean;
}

const HelpContainer = ({
  text = 'Need help?',
  linkText = 'Contact customer support',
  linkURL = 'https://membersupport.red.virgin.com/hc/en-gb',
  target = true
}: HelpContainerProps) => {
  return (
    <>
      <style jsx>{`
        .details-help {
          justify-content: flex-start;
          font-family: ${FontFamilies.barlow};
          margin-bottom: 10px;
        }

        .details-help .grid-item {
          padding: 0 !important;
        }

        .details-help span {
          text-decoration: underline;
          color: ${color.blackUnderline};
          margin-left: 5px;
        }
      `}</style>
      <GridContainer className="details-help">
        <GridItem xs={12} data-testid="help-box">
          {text}
          <StyledCopyAnchor to={linkURL} target={target} external>
            <span>{linkText}</span>
          </StyledCopyAnchor>
        </GridItem>
      </GridContainer>
    </>
  );
};

export default HelpContainer;
