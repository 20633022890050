import React, { useState } from 'react';
import HelpIcon from '@material-ui/icons/Help';
import { Tooltip, Typography, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { color, FontFamilies } from '../../styles';

const useStyles = makeStyles({
  tooltip: {
    backgroundColor: color.white,
    color: color.black,
    borderRadius: 5,
    boxShadow: `1px 2px 9px ${color.lightGrey}`,
    padding: '1em',
    fontFamily: FontFamilies.barlow
  },
  title: {
    fontWeight: 'bold'
  }
});

interface InfoTooltipProps {
  title?: string;
  body?: string;
}

const InfoTooltip = ({ title, body }: InfoTooltipProps) => {
  const [openTooltip, setOpenTooltipValue] = useState(false);
  const classes = useStyles();

  const toggleClick = () => {
    setOpenTooltipValue(!openTooltip);
  };

  return (
    <>
      <Tooltip
        data-testid="tooltip-box"
        onClose={() => toggleClick()}
        open={openTooltip === true}
        title={
          <React.Fragment>
            <Typography className={classes.title} align="left" color="inherit">
              {title}
            </Typography>
            {body}
          </React.Fragment>
        }
        PopperProps={{
          disablePortal: true
        }}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        classes={{ tooltip: classes.tooltip }}
      >
        <IconButton onClick={() => toggleClick()}>
          <HelpIcon style={{ color: color.black }} />
        </IconButton>
      </Tooltip>
    </>
  );
};

export default InfoTooltip;
