import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { PATHS } from '../../routes/paths';
import jwt_decode from 'jwt-decode';

import {
  setAccessToken,
  setAuthState,
  setContinueToken,
  setDataConsent,
  setExternalId
} from '../../features/auth/authSlice';

import { setLoading } from '../../features/profile/profileSlice';
import { LoadSpinner } from '../../components/LoadSpinner';
import { setStorageValue } from '../../utils/helperFunctions';

interface SessionToken {
  sub: string;
  token: string;
  consentEnabled: boolean;
}

const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};

const Init: FC = () => {
  const [warning, setWarning] = useState<boolean>(false);
  const warningFunc = setTimeout(() => setWarning(true), 5000);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const query = useQuery();
  const tokenInUrl = query.get('token');
  const stateInUrl = query.get('state');

  useEffect(() => {
    if (tokenInUrl && stateInUrl) {
      const decodedToken = jwt_decode(tokenInUrl) as SessionToken;

      const data = {
        accessToken: JSON.stringify(decodedToken.token),
        externalId: JSON.stringify(decodedToken.sub),
        continueToken: tokenInUrl,
        authState: stateInUrl
      };
      setStorageValue('storageData', data);

      dispatch(setAccessToken(decodedToken.token));
      dispatch(setExternalId(decodedToken.sub));
      dispatch(setDataConsent(decodedToken.consentEnabled));
      dispatch(setContinueToken(tokenInUrl));
      dispatch(setAuthState(stateInUrl));
      dispatch(setLoading(true));
      navigate(PATHS.GUARD);
    }
    return () => clearTimeout(warningFunc);
  }, [dispatch, navigate, stateInUrl, tokenInUrl, warningFunc]);

  return (
    <>
      <LoadSpinner />
      {warning && (
        <div className="no-results-text top-space">
          Something went wrong. <br />
          Please try logging in again.
        </div>
      )}
    </>
  );
};

export default Init;
