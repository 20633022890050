import React from 'react';

import { color, FontFamilies, media } from '../../styles';
import { GridItem } from '@virgin-core/components';

interface StepTitleProps {
  activeStep?: string;
}

const StepTitle = ({ activeStep = '1' }: StepTitleProps) => {
  return (
    <>
      <style jsx>{`
        .step-title {
          color: ${color.grey07};
          font-family: ${FontFamilies.barlow};
          font-weight: 400;
          font-size: 14px;
          line-height: 48px;
          margin-top: 15px;
        }

        @media ${media.tabletAndLower} {
          .step-title {
            margin-top: 30px;
          }
        }

        @media ${media.mobileAndLower} {
          .step-title {
            margin-top: 30px;
          }
        }
      `}</style>
      <GridItem className="step-title" xs={12}>
        Step {activeStep} of 3
      </GridItem>
    </>
  );
};

export default StepTitle;
