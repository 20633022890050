import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Formik, Form, Field } from 'formik';
import { authSelector } from '../../features/auth/authSlice';
import { createProfile, profileSelector } from '../../features/profile/profileSlice';
import { PostableProfileItems } from '../../features/profile/types';
import { DOTCOM_PATHS, PATHS } from '../../routes/paths';
import { GridContainer, GridItem, MenuItem, TextField } from '@virgin-core/components';
import { ViewingRegion } from '../../utils/constants';
import { color, FontFamilies } from '../../styles';
import Button from '@material-ui/core/Button/Button';
import Checkbox from '@material-ui/core/Checkbox/Checkbox';
import StyledCopyAnchor from '../../components/StyledCopyAnchor';
import { personaDetailsValidationSchema } from '../../utils/validationSchema';
import { getRegionDisplayName } from '../../utils/helperFunctions';
import StepTitle from '../../components/StepTitle';
import { phoneSelector } from '../../features/auth/phoneSlice';
import InfoTooltip from '../../components/InfoTooltip';

type PersonalDetailsFormPropsType = {
  country: string;
  firstName: string;
  lastName: string;
  dateOfBirth: any;
  postcode: string;
  termsVersion: string;
  marketingEmail: boolean;
  marketingSms: boolean;
  marketingTel: boolean;
  mobileVerified: boolean;
  mobileNumber: string;
};

const PersonalDetails = () => {
  const { accessToken } = useSelector(authSelector);
  const { mobileNumber } = useSelector(phoneSelector);
  const profileState = useSelector(profileSelector);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (profileState?.profile?.complete) navigate(PATHS.CONTINUE);
  }, [profileState, navigate]);

  const personaDetailsInitialVals: PersonalDetailsFormPropsType = {
    country: 'GB',
    firstName: '',
    lastName: '',
    dateOfBirth: null, // if dateOfBirth is defined as '' yup will throw a invalid date error
    postcode: '',
    // Consent is opt-out on form but opt-in in API, so reverse boolean value
    marketingEmail: true,
    marketingSms: true,
    marketingTel: true,
    termsVersion: '',
    mobileVerified: false,
    mobileNumber: ''
  };

  const handleSubmit = (values: PersonalDetailsFormPropsType) => {
    const payload: PostableProfileItems = {
      country: values.country,
      dateOfBirth: format(values.dateOfBirth, 'yyyy-MM-dd'),
      firstName: values.firstName,
      lastName: values.lastName,
      marketingEmail: values.marketingEmail,
      marketingSms: values.marketingSms,
      marketingTel: values.marketingTel,
      postcode: values.postcode,
      termsVersion: values.termsVersion,
      mobile: mobileNumber,
      mobileVerified: true
    };
    dispatch(createProfile(accessToken, payload));
  };

  return profileState.loading ? (
    <>
      Loading...
      {!accessToken && (
        <>No accessToken available to app - restart app flow via /?token=[your.lovely.token]</>
      )}
    </>
  ) : (
    <>
      {accessToken ? (
        <>
          <style jsx>{`
            h3 {
              font-family: ${FontFamilies.barlow};
              font-size: 20px;
              font-weight: 600;
              line-height: 1.5;
              margin: 0;
            }

            .section-header {
              margin-bottom: 16px;
            }

            .title {
              color: ${color.brandPrimary};
              font-family: ${FontFamilies.barlow};
              font-weight: 600;
              font-style: italic;
              font-size: 28px;
              line-height: 32px;
            }

            .details-header {
              justify-content: flex-start;
              margin-bottom: 32px;
              font-family: ${FontFamilies.barlow};
            }

            .details-header .grid-item {
              padding: 0 !important;
            }

            .MuiTextField-root .MuiFormLabel-root.Mui-error,
            .MuiTextField-root .MuiFormLabel-root.Mui-error.Mui-focused {
              color: ${color.redHover} !important;
            }
            [class*='MuiFormLabel-root-'].Mui-error,
            [class*='MuiFormLabel-root-'].Mui-error.Mui-focused {
              color: ${color.redHover} !important;
            }
            .MuiTextField-root .MuiFormHelperText-root.Mui-error {
              color: ${color.redHover} !important;
            }
            [class*='MuiFormHelperText-root-'].Mui-error {
              color: ${color.redHover} !important;
            }
            .MuiTextField-root .MuiInputBase-root.Mui-error:after {
              border-bottom-color: ${color.redHover} !important;
            }
            [class*='MuiInputBase-root-'].Mui-error:after {
              border-bottom-color: ${color.redHover} !important;
            }
            .MuiTextField-root .MuiInputBase-root:before {
              border-bottom-color: ${color.lighterGrey};
              border-width: 2px;
            }
            [class*='MuiInputBase-root-']:before {
              border-bottom-color: ${color.lighterGrey};
              border-width: 2px;
            }
            .MuiTextField-root .MuiInputBase-root:hover:not(.Mui-disabled):before {
              border-bottom-color: ${color.textStandard} !important;
            }
            [class*='MuiInputBase-root-']:hover:not(.Mui-disabled):before {
              border-bottom-color: ${color.textStandard} !important;
            }
            .MuiTextField-root .MuiInputBase-root:after,
            .MuiTextField-root .MuiInputBase-root.Mui-focused:after {
              border-bottom-color: ${color.textStandard};
            }
            [class*='MuiInputBase-root-']:after,
            [class*='MuiInputBase-root-'].Mui-focused:after {
              border-bottom-color: ${color.textStandard};
            }
            .MuiTextField-root MuiFormLabel-root,
            .MuiTextField-root MuiFormLabel-root.Mui-focused {
              font-family: ${FontFamilies.barlow} !important;
              color: ${color.lighterGrey} !important;
            }
            [class*='MuiFormLabel-root-'],
            [class*='MuiFormLabel-root-'].Mui-focused {
              font-family: ${FontFamilies.barlow} !important;
              color: ${color.lighterGrey} !important;
            }
            .MuiTextField-root *,
            .checkbox-wrapper .MuiFormControlLabel-label,
            .MuiPickersModal-dialogRoot * {
              font-family: ${FontFamilies.barlow} !important;
              letter-spacing: initial !important;
            }
            [class*='MuiTextField-root-'] *,
            .checkbox-wrapper [class*='MuiFormControlLabel-label-'],
            [class*='MuiPickersModal-dialogRoot-'] * {
              font-family: ${FontFamilies.barlow} !important;
              letter-spacing: initial !important;
            }
            .MuiListItem-root .MuiTouchRipple-child {
              background-color: transparent;
            }
            [class*='MuiListItem-root-'] [class*='MuiTouchRipple-child-'] {
              background-color: transparent;
            }

            .input-field,
            .dob-field {
              min-height: 72px;
            }

            .dob-field {
              margin-bottom: 16px;
            }

            .dob-field .MuiIconButton-root {
              padding: 0;
            }

            .marketing-pref-item {
              padding: 12px 0 !important;
            }

            .dob-note {
              color: ${color.lighterGrey};
              font-size: 14px;
              font-family: ${FontFamilies.barlow};
            }

            .comm-block {
              padding: 12px 0;
              margin-top: 16px;
              font-family: ${FontFamilies.barlow};
            }

            .checkbox-wrapper,
            .checkbox-terms-wrapper {
              display: flex;
              flex-direction: row-reverse;
              justify-content: flex-end;
              align-items: flex-start;
              font-family: ${FontFamilies.barlow};
            }

            .checkbox-terms-wrapper {
              margin-bottom: 24px;
            }

            .MuiTextField-root .MuiInputLabel-shrink {
              transform: translate(0, 1.5px) scale(0.86);
              font-family: ${FontFamilies.barlow};
              color: ${color.lighterGrey};
            }

            [class*='MuiInputLabel-shrink-'] {
              transform: translate(0, 1.5px) scale(0.86);
              font-family: ${FontFamilies.barlow};
              color: ${color.lighterGrey};
            }

            .MuiListItem-root {
              display: block;
              font-weight: 600;
              font-family: ${FontFamilies.barlow} !important;
            }

            [class*='MuiListItem-root-'] {
              display: block;
              font-weight: 600;
              font-family: ${FontFamilies.barlow} !important;
            }

            .MuiTooltip-tooltip {
              font-size: 0.875rem;
            }

            [class*='MuiTooltip-tooltip-'] {
              font-size: 0.875rem;
              background-color: reg;
            }

            MuiListItem-root:hover,
            .MuiListItem-root.Mui-selected {
              background-color: transparent !important;
              color: ${color.brandPrimary};
              transition: all 250ms ease 0s;
            }

            [class*='MuiListItem-root-']:hover,
            [class*='MuiListItem-root-'].Mui-selected {
              background-color: transparent !important;
              color: ${color.brandPrimary};
              transition: all 250ms ease 0s;
            }

            .MuiPickersModal-dialogRoot .MuiPickersToolbar-toolbar,
            .MuiPickersModal-dialogRoot .MuiPickersDay-daySelected {
              font-family: ${FontFamilies.barlow} !important;
              background-color: ${color.brandPrimary} !important;
            }
            .MuiPickersModal-dialogRoot .MuiButton-textPrimary,
            .MuiPickersModal-dialogRoot .MuiPickersYear-yearSelected,
            .MuiPickersModal-dialogRoot .MuiPickersYear-root:focus {
              font-family: ${FontFamilies.barlow} !important;
              color: ${color.brandPrimary} !important;
            }

            button.MuiButtonBase-root.MuiIconButton-root {
              padding: 0;
            }

            .postcode-field [class*='MuiIconButton-root-'] {
              color: ${color.lightGrey} !important;
              background-image: radial-gradient(circle at 50% 50%, black 25%, transparent 25%);
            }

            .MuiButtonBase-root.MuiCheckbox-root {
              color: ${color.lightGrey};
              padding: 0 8px 0 0;
            }
            .MuiButtonBase-root.MuiCheckbox-root.Mui-checked {
              color: ${color.brandPrimary};
            }

            .button-container .MuiButton-label {
              min-width: 109px;
            }

            .MuiButton-root {
              letter-spacing: normal;
            }

            .MuiIconButton-colorSecondary:hover {
              background-color: transparent !important;
            }

            .MuiTouchRipple-root {
              visibilty: none !important;
              color: transparent !important;
            }

            .terms-section {
              margin: 24px 0;
            }

            .terms-error {
              position: relative;
              background-color: ${color.darkPinkBackground};
              padding: 12px 16px 12px 52px;
              box-sizing: border-box;
            }
            .terms-error:before {
              position: absolute;
              top: 14px;
              left: 19px;
              display: block;
              background-color: ${color.brandPrimary};
              border-radius: 100%;
              content: '!';
              font-size: 14px;
              font-weight: 600;
              color: ${color.white};
              text-align: center;
              width: 20px;
              height: 20px;
              line-height: 1.3;
            }

            .instructions {
              font-family: ${FontFamilies.barlow};
              line-height: 1.5;
            }

            .button-container {
              padding: 24px 0 10px;
            }

            .button-container .grid-item {
              padding: 0 !important;
            }

            .submit-button {
              text-transform: none;
              font-family: ${FontFamilies.barlow};
              font-size: 15px;
              font-weight: bold;
              border: 1px solid rgb(225, 10, 10);
              border-radius: 8px;
              color: ${color.white};
              background-color: ${color.brandPrimary};
              min-width: 140px;
              height: 48px;
              cursor: pointer;
              line-height: 1.5;
              padding: 0px 16px;
              text-align: center;
              transition: all 150ms ease-out 0s;
              width: 100%;
            }

            .submit-button:hover {
              background-color: ${color.brandPrimary} !important;
            }

            .Mui-disabled {
              background-color: ${color.disbaledRed};
              border-color: ${color.disbaledRed};
            }

            .MuiButton-root.Mui-disabled {
              color: ${color.white};
            }

            .MuiButtonBase-root.MuiCheckbox-root.MuiCheckbox-colorSecondary.MuiIconButton-colorSecondary.Mui-focusVisible {
              border: 1px solid black;
              border-radius: 0px;
            }

            .MuiInputAdornment-positionStart {
              margin-left: -8px;
            }
          `}</style>
          <Formik
            validateOnBlur={true}
            validateOnChange={true}
            initialValues={personaDetailsInitialVals}
            validationSchema={personaDetailsValidationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, setFieldValue, touched, errors, isValid, dirty, handleBlur }) => {
              return (
                <Form data-testid="onboarding-personal-details">
                  <GridContainer className="details-header">
                    <StepTitle activeStep="3" />
                    <GridItem className="title" xs={12} md={6}>
                      <>Build your profile</>
                    </GridItem>
                    <GridItem xs={12}>
                      <p>Enter your personal details below</p>
                    </GridItem>
                  </GridContainer>
                  <h3 className="section-header">Your details</h3>
                  <Field
                    data-testid="onboarding.personalDetails.country"
                    className="input-field"
                    component={TextField}
                    id="country"
                    name="country"
                    as="select"
                    label="Country*"
                    placeholder="e.g. United Kingdom"
                    error={touched.country && !!errors.country}
                    helperText={touched.country && errors.country}
                    onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                      setFieldValue('country', event.target.value);
                    }}
                    value={values.country}
                    select
                    style={{ pointerEvents: 'all' }}
                    fullWidth
                  >
                    {Object.keys(ViewingRegion).map((enumKey) => (
                      <MenuItem
                        data-testid={`menu-item-${enumKey}`}
                        id={`menu-item-${enumKey}`}
                        key={`menu-item-${enumKey}`}
                        value={enumKey}
                      >
                        {getRegionDisplayName(enumKey as ViewingRegion)}
                      </MenuItem>
                    ))}
                  </Field>
                  <Field
                    data-testid="onboarding.personalDetails.firstName"
                    className="input-field"
                    label="First name*"
                    placeholder="John"
                    component={TextField}
                    type="text"
                    fullWidth
                    id="firstName"
                    name="firstName"
                    value={values.firstName}
                    error={touched.firstName && !!errors.firstName}
                    helperText={touched.firstName && errors.firstName}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      setFieldValue('firstName', event.target.value)
                    }
                    onBlur={handleBlur}
                  />
                  <Field
                    data-testid="onboarding.personalDetails.lastName"
                    className="input-field"
                    label="Last name*"
                    placeholder="Doe"
                    component={TextField}
                    fullWidth
                    id="lastName"
                    name="lastName"
                    value={values.lastName}
                    error={touched.lastName && !!errors.lastName}
                    helperText={touched.lastName && errors.lastName}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      setFieldValue('lastName', event.target.value)
                    }
                    onBlur={handleBlur}
                  />
                  <div className="dob-field">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        data-testid="onboarding.personalDetails.dateOfBirth"
                        id="date-picker-dialog"
                        name="dateOfBirth"
                        label="Birthday* (dd/mm/yyyyy)"
                        placeholder={'DD/MM/YYYY'}
                        format={'dd/MM/yyyy'}
                        value={values.dateOfBirth}
                        error={touched.dateOfBirth && !!errors.dateOfBirth}
                        helperText={touched.dateOfBirth && errors.dateOfBirth}
                        onChange={(value) => setFieldValue('dateOfBirth', value)}
                        onBlur={handleBlur}
                        KeyboardButtonProps={{
                          disabled: true,
                          style: { display: 'none' }
                        }}
                        InputProps={{
                          endAdornment: (
                            <InfoTooltip
                              title="Why are we asking for your birthday?"
                              body="We ask for your date of birth as you must be 18 years or over to join Virgin ID and for account security."
                            />
                          )
                        }}
                        InputAdornmentProps={{
                          position: 'start'
                        }}
                        fullWidth
                      />
                    </MuiPickersUtilsProvider>
                    <div className="dob-note">You must be 18+ to join Virgin Identity</div>
                  </div>

                  <Field
                    data-testid="onboarding.personalDetails.postcode"
                    className="input-field postcode-field"
                    label={'Postcode*'}
                    placeholder={'W2 6ET'}
                    component={TextField}
                    fullWidth
                    id="postcode"
                    name="postcode"
                    value={values.postcode}
                    error={touched.postcode && !!errors.postcode}
                    helperText={touched.postcode && errors.postcode}
                    inputProps={{ style: { textTransform: 'uppercase' } }}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      setFieldValue('postcode', event.target.value)
                    }
                    onBlur={handleBlur}
                    InputProps={{
                      endAdornment: (
                        <InfoTooltip
                          title=""
                          body="We’ll use your postcode to offer you tailored rewards and for the security of your account."
                        />
                      )
                    }}
                    InputAdornmentProps={{
                      position: 'start'
                    }}
                  />
                  <div className="comm-block">
                    <h3>Communication preference</h3>
                    <p>
                      When you sign up to Virgin ID you can choose how all connected Virgin
                      companies contact you
                    </p>

                    <p>Check the boxes if you'd prefer NOT to be contacted by:</p>
                    <GridContainer>
                      <GridItem className="marketing-pref-item" xs={12}>
                        <label className="checkbox-wrapper">
                          Email
                          <Checkbox
                            data-testid="checkbox-email"
                            name="marketingEmail"
                            checked={!values.marketingEmail}
                            onChange={(event: any, checked: boolean) =>
                              setFieldValue('marketingEmail', !checked)
                            }
                          />
                        </label>
                      </GridItem>
                      <GridItem className="marketing-pref-item" xs={12}>
                        <label className="checkbox-wrapper">
                          SMS
                          <Checkbox
                            data-testid="checkbox-sms"
                            name="marketingSms"
                            checked={!values.marketingSms}
                            onChange={(event: any, checked: boolean) =>
                              setFieldValue('marketingSms', !checked)
                            }
                          />
                        </label>
                      </GridItem>
                      <GridItem className="marketing-pref-item" xs={12}>
                        <label className="checkbox-wrapper">
                          Telephone
                          <Checkbox
                            data-testid="checkbox-tel"
                            name="marketingTel"
                            checked={!values.marketingTel}
                            onChange={(event: any, checked: boolean) =>
                              setFieldValue('marketingTel', !checked)
                            }
                          />
                        </label>
                      </GridItem>
                    </GridContainer>
                    <p>You can always change these later in your Virgin ID account settings.</p>
                  </div>
                  <section className="terms-section">
                    <h3 className="section-header">Virgin ID Terms &amp; Conditions</h3>
                    <p className="instructions">
                      When you use Virgin ID, we use the information to make your experience better.
                      This means sharing it with Virgin ID partners you’ve interacted with and
                      getting information back from them.
                      <br />
                      <br />
                      Our&nbsp;
                      <StyledCopyAnchor to={DOTCOM_PATHS.HOME + PATHS.PRIVACY} target external>
                        Privacy Policy&nbsp;
                      </StyledCopyAnchor>
                      tells you more about how we collect, use, share and protect your personal
                      information.
                      <br />
                      <br />
                      We use the information to make your experience better and show you the kind of
                      rewards you’ll love.
                    </p>
                    <div className="checkbox-terms-wrapper">
                      <label className="checkbox-terms">
                        By ticking this box you’re also agreeing to the&nbsp;
                        <StyledCopyAnchor
                          to={DOTCOM_PATHS.HOME + PATHS.TERMS_AND_CONDITIONS}
                          target
                          external
                        >
                          Virgin ID Programme Terms and Conditions.
                        </StyledCopyAnchor>
                      </label>
                      <Checkbox
                        data-testid="checkbox-terms"
                        name="termsVersion"
                        checked={values.termsVersion === '1'}
                        onChange={(_, checked: boolean) => {
                          touched.termsVersion = true;
                          setFieldValue('termsVersion', checked ? '1' : '');
                        }}
                      />
                    </div>
                    {touched.termsVersion && errors.termsVersion && (
                      <div className="terms-error">{errors.termsVersion}</div>
                    )}
                  </section>
                  <div className="button-container">
                    <GridItem xs={12} sm={4}>
                      <Button
                        data-testid="onboarding-personal-details-button"
                        className="submit-button"
                        type="submit"
                        disabled={!(isValid && dirty)}
                      >
                        Continue
                      </Button>
                    </GridItem>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </>
      ) : (
        <>
          <div className="no-results-text">
            Something went wrong. <br />
            Please try logging in again.
          </div>
        </>
      )}
    </>
  );
};

export default PersonalDetails;
