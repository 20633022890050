import axios from 'axios';
import { PostableProfileItems } from './types';
import { REACT_APP_API_URL } from '../../config';

const API_URL = `${REACT_APP_API_URL}/profiles/v1`;

export const getProfileAPI = async ({
  accessToken,
  externalId
}: {
  accessToken: string;
  externalId: string;
}) => {
  try {
    const params = new URLSearchParams([['externalId', externalId]]);

    const resp = await axios.get(`${API_URL}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
      params: params
    });
    return resp.data;
  } catch (e) {
    console.log(e);
    // TODO: implement refresh & 400-range responses
  }
};

export const postProfileAPI = async ({
  payload,
  accessToken
}: {
  payload: PostableProfileItems;
  accessToken: string;
}) => {
  try {
    const resp = await axios.post(API_URL, payload, {
      headers: { Authorization: `Bearer ${accessToken}` }
    });
    return resp.data;
  } catch (e) {
    console.log(e);
  }
};
