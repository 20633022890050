import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../..';

export interface CookieState {
  showModal: boolean;
  showBanner: boolean;
}

const initialCookieState: CookieState = {
  showBanner: false,
  showModal: false
};

const cookieSlice = createSlice({
  name: 'cookie',
  initialState: initialCookieState,
  reducers: {
    setShowBanner(state, action: PayloadAction<boolean>) {
      state.showBanner = action.payload;
    },
    setShowModal(state, action: PayloadAction<boolean>) {
      state.showModal = action.payload;
    }
  }
});

export const showCookieModal = (): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(setShowModal(true));
    dispatch(setShowBanner(true));
  };
};

export const hideCookieModal = (): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(setShowModal(false));
    dispatch(setShowBanner(true));
  };
};

export const showCookieBanner = (): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(setShowModal(false));
    dispatch(setShowBanner(true));
  };
};

export const hideCookieBanner = (): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(setShowModal(false));
    dispatch(setShowBanner(false));
  };
};

// actions
export const { setShowBanner, setShowModal } = cookieSlice.actions;
export default cookieSlice.reducer;
export const cookieSelector = (state: { cookie: CookieState }) => state.cookie;
