import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { authSelector } from '../../features/auth/authSlice';
import { profileSelector } from '../../features/profile/profileSlice';
import { PATHS } from '../../routes/paths';
import { GridContainer, GridItem } from '@virgin-core/components';
import { color, FontFamilies } from '../../styles';
import Button from '@material-ui/core/Button/Button';
import { hashFormatMobileNumber } from '../../dataImplementation/partnerConnectIdData';
import HelpContainer from '../../components/HelpContainer';
import StepTitle from '../../components/StepTitle';
import {
  phoneSelector,
  setErrorsCode,
  setPhoneRes,
  verifyCode
} from '../../features/auth/phoneSlice';
import { codeValidationSchema } from '../../utils/validationSchema';

const CODE_LENGTH = new Array(6).fill(0);

type CodeFormPropsType = {
  code: string;
};

interface MobileProp {
  mobile: string;
}

const MobileVerify = () => {
  const { accessToken, externalId } = useSelector(authSelector);
  const profileState = useSelector(profileSelector);
  const phoneState = useSelector(phoneSelector);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation().state as MobileProp;
  const [value, setValue] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [focused, setFocused] = useState(false);
  const digits = value.split('');
  const inputRef = React.createRef<HTMLInputElement>();

  useEffect(() => {
    dispatch(setPhoneRes(false));
  });

  useEffect(() => {
    if (phoneState?.codeValid) {
      navigate(PATHS.PERSONAL_DETAILS, { state: { mobile: phoneState.mobileNumber } });
    }
  }, [phoneState, navigate]);

  useEffect(() => {
    setDisabled(value.length < CODE_LENGTH.length ? false : true);
  }, [value]);

  const codeInitialVals: CodeFormPropsType = {
    code: ''
  };

  const handleSubmit = async () => {
    dispatch(verifyCode(location.mobile, value, externalId));
  };

  const handleClick = () => {
    inputRef.current?.focus();
    dispatch(setErrorsCode(null));
  };

  const handleInputFocus = () => {
    setFocused(true);
  };

  const handleInputBlur = () => {
    setFocused(true);
  };

  const handleKeyUp = (e: any) => {
    if (e.key === 'Backspace') {
      setValue((prevValue: any) => {
        return prevValue.slice(0, prevValue.length - 1);
      });
    }
  };

  const handleChange = (e: any) => {
    const value = e.target.value;
    setValue((prevValue: any) => {
      if (prevValue.length >= CODE_LENGTH.length) return prevValue.slice(0, CODE_LENGTH.length);
      return (prevValue + value).slice(0, CODE_LENGTH.length);
    });
  };

  const selectedIndex = digits.length < CODE_LENGTH.length ? digits.length : CODE_LENGTH.length - 1;
  const hideInput = !(digits.length < CODE_LENGTH.length);

  return profileState.loading ? (
    <>
      Loading...
      {!accessToken && (
        <>No accessToken available to app - restart app flow via /?token=[your.lovely.token]</>
      )}
    </>
  ) : (
    <>
      {accessToken ? (
        <>
          <style jsx>{`
            h3 {
              font-family: ${FontFamilies.barlow};
              font-size: 20px;
              font-weight: 600;
              line-height: 1.5;
              margin: 0;
            }

            .section-header {
              margin-bottom: 16px;
            }

            .title {
              color: ${color.brandPrimary};
              font-family: ${FontFamilies.barlow};
              font-weight: 600;
              font-style: italic;
              font-size: 28px;
              line-height: 32px;
            }

            .step-title {
              color: ${color.grey07};
              font-family: ${FontFamilies.barlow};
              font-weight: 400;
              font-size: 14px;
              line-height: 48px;
            }

            .details-header {
              justify-content: flex-start;
              font-family: ${FontFamilies.barlow};
              margin-bottom: 20px;
            }

            .details-header .grid-item {
              padding: 0 !important;
            }

            .MuiTextField-root .MuiFormLabel-root.Mui-error,
            .MuiTextField-root .MuiFormLabel-root.Mui-error.Mui-focused {
              color: ${color.redHover} !important;
            }
            [class*='MuiFormLabel-root-'].Mui-error,
            [class*='MuiFormLabel-root-'].Mui-error.Mui-focused {
              color: ${color.redHover} !important;
            }
            .MuiTextField-root .MuiFormHelperText-root.Mui-error {
              color: ${color.redHover} !important;
            }
            [class*='MuiFormHelperText-root-'].Mui-error {
              color: ${color.redHover} !important;
            }
            .MuiTextField-root .MuiInputBase-root.Mui-error:after {
              //border-bottom-color: ${color.redHover} !important;
            }
            [class*='MuiInputBase-root-'].Mui-error:after {
              //border-bottom-color: ${color.redHover} !important;
            }
            .MuiTextField-root .MuiInputBase-root:before {
              //border-bottom-color: ${color.lighterGrey};
              //border-width: 2px;
            }
            [class*='MuiInputBase-root-']:before {
              //border-bottom-color: ${color.lighterGrey};
              border-width: 2px;
            }
            .MuiTextField-root .MuiInputBase-root:hover:not(.Mui-disabled):before {
              //border-bottom-color: ${color.textStandard} !important;
            }
            [class*='MuiInputBase-root-']:hover:not(.Mui-disabled):before {
              //border-bottom-color: ${color.textStandard} !important;
            }
            .MuiTextField-root .MuiInputBase-root:after,
            .MuiTextField-root .MuiInputBase-root.Mui-focused:after {
              //border-bottom-color: ${color.textStandard};
            }
            [class*='MuiInputBase-root-']:after,
            [class*='MuiInputBase-root-'].Mui-focused:after {
              //border-bottom-color: ${color.textStandard};
            }
            .MuiTextField-root MuiFormLabel-root,
            .MuiTextField-root MuiFormLabel-root.Mui-focused {
              font-family: ${FontFamilies.barlow} !important;
              color: ${color.lighterGrey} !important;
            }
            [class*='MuiFormLabel-root-'],
            [class*='MuiFormLabel-root-'].Mui-focused {
              font-family: ${FontFamilies.barlow} !important;
              color: ${color.lighterGrey} !important;
            }
            .MuiTextField-root *,
            .checkbox-wrapper .MuiFormControlLabel-label,
            .MuiPickersModal-dialogRoot * {
              font-family: ${FontFamilies.barlow} !important;
              letter-spacing: initial !important;
            }
            [class*='MuiTextField-root-'] *,
            .checkbox-wrapper [class*='MuiFormControlLabel-label-'],
            [class*='MuiPickersModal-dialogRoot-'] * {
              font-family: ${FontFamilies.barlow} !important;
              letter-spacing: initial !important;
            }
            .MuiListItem-root .MuiTouchRipple-child {
              background-color: transparent;
            }
            [class*='MuiListItem-root-'] [class*='MuiTouchRipple-child-'] {
              background-color: transparent;
            }

            .input-field,
            .dob-field {
              min-height: 72px;
            }

            .dob-field {
              margin-bottom: 16px;
            }

            .dob-field .MuiIconButton-root {
              padding: 0;
            }

            .marketing-pref-item {
              padding: 12px 0 !important;
            }

            .dob-note {
              color: ${color.lighterGrey};
              font-size: 14px;
              font-family: ${FontFamilies.barlow};
            }

            .comm-block {
              padding: 12px 0;
              margin-top: 16px;
              font-family: ${FontFamilies.barlow};
            }

            .checkbox-wrapper,
            .checkbox-terms-wrapper {
              display: flex;
              flex-direction: row-reverse;
              justify-content: flex-end;
              align-items: flex-start;
              font-family: ${FontFamilies.barlow};
            }

            .checkbox-terms-wrapper {
              margin-bottom: 24px;
            }

            .MuiTextField-root .MuiInputLabel-shrink {
              transform: translate(0, 1.5px) scale(0.86);
              font-family: ${FontFamilies.barlow};
              color: ${color.lighterGrey};
            }

            [class*='MuiInputLabel-shrink-'] {
              transform: translate(0, 1.5px) scale(0.86);
              font-family: ${FontFamilies.barlow};
              color: ${color.lighterGrey};
            }

            .MuiListItem-root {
              display: block;
              font-weight: 600;
              font-family: ${FontFamilies.barlow} !important;
            }

            [class*='MuiListItem-root-'] {
              display: block;
              font-weight: 600;
              font-family: ${FontFamilies.barlow} !important;
            }

            .MuiTooltip-tooltip {
              font-size: 0.875rem;
            }

            [class*='MuiTooltip-tooltip-'] {
              font-size: 0.875rem;
            }

            MuiListItem-root:hover,
            .MuiListItem-root.Mui-selected {
              background-color: transparent !important;
              color: ${color.brandPrimary};
              transition: all 250ms ease 0s;
            }

            [class*='MuiListItem-root-']:hover,
            [class*='MuiListItem-root-'].Mui-selected {
              background-color: transparent !important;
              color: ${color.brandPrimary};
              transition: all 250ms ease 0s;
            }

            .MuiPickersModal-dialogRoot .MuiPickersToolbar-toolbar,
            .MuiPickersModal-dialogRoot .MuiPickersDay-daySelected {
              font-family: ${FontFamilies.barlow} !important;
              background-color: ${color.brandPrimary} !important;
            }
            .MuiPickersModal-dialogRoot .MuiButton-textPrimary,
            .MuiPickersModal-dialogRoot .MuiPickersYear-yearSelected,
            .MuiPickersModal-dialogRoot .MuiPickersYear-root:focus {
              font-family: ${FontFamilies.barlow} !important;
              color: ${color.brandPrimary} !important;
            }

            .postcode-field .MuiIconButton-root {
              color: ${color.lightGrey} !important;
              background-image: radial-gradient(circle at 50% 50%, black 25%, transparent 25%);
            }

            .postcode-field [class*='MuiIconButton-root-'] {
              color: ${color.lightGrey} !important;
              background-image: radial-gradient(circle at 50% 50%, black 25%, transparent 25%);
            }

            .MuiButtonBase-root.MuiCheckbox-root {
              color: ${color.lightGrey};
              padding: 0 8px 0 0;
            }
            .MuiButtonBase-root.MuiCheckbox-root.Mui-checked {
              color: ${color.brandPrimary};
            }

            .button-container .MuiButton-label {
              min-width: 109px;
            }

            .MuiButton-root {
              letter-spacing: normal;
            }

            .MuiIconButton-colorSecondary:hover {
              background-color: transparent !important;
            }

            .MuiTouchRipple-root {
              visibilty: none !important;
              color: transparent !important;
            }

            .terms-section {
              margin: 24px 0;
            }

            .terms-error {
              position: relative;
              background-color: ${color.darkPinkBackground};
              padding: 12px 16px 12px 52px;
              box-sizing: border-box;
            }
            .terms-error:before {
              position: absolute;
              top: 14px;
              left: 19px;
              display: block;
              background-color: ${color.brandPrimary};
              border-radius: 100%;
              content: '!';
              font-size: 14px;
              font-weight: 600;
              color: ${color.white};
              text-align: center;
              width: 20px;
              height: 20px;
              line-height: 1.3;
            }

            .instructions {
              font-family: ${FontFamilies.barlow};
              line-height: 1.5;
            }

            .button-container {
              padding: 24px 0 10px;
            }

            .button-container .grid-item {
              padding: 0 !important;
            }

            .submit-button {
              text-transform: none;
              font-family: ${FontFamilies.barlow};
              font-size: 15px;
              font-weight: bold;
              border: 1px solid rgb(225, 10, 10);
              border-radius: 8px;
              color: ${color.white};
              background-color: ${color.brandPrimary};
              min-width: 140px;
              height: 48px;
              cursor: pointer;
              line-height: 1.5;
              padding: 0px 16px;
              text-align: center;
              transition: all 150ms ease-out 0s;
              width: 100%;
            }

            .submit-button:hover {
              background-color: ${color.brandPrimary} !important;
            }

            .display {
              border-bottom: 2px solid ${color.textStandard};
              width: 20px;
              height: 58px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 32px;
              position: relative;
              margin-left: 10px;
            }
            .display:last-child {
              border-right: none;
            }
            .display:first-child {
              margin-left: 0px;
            }

            .wrap {
              //border: 1px solid rgba(0, 0, 0, 0.2);
              display: inline-block;
              position: relative;
              display: flex;
            }

            .code-container {
              display: flex;
            }

            .error-text {
              color: ${color.brandPrimary};
              margin-bottom: 20px;
            }

            .input {
              position: absolute;
              border: none;
              font-size: 32px;
              text-align: center;
              background-color: transparent;
              outline: none;
            }

            .shadows {
              position: absolute;
              left: 0;
              top: 0;
              bottom: 0;
              right: 0;
            }

            .enter-code {
              font-size: 20px;
              margin-top: 10px;
            }

            .MuiButtonBase-root.Mui-disabled {
              background-color: ${color.disbaledRed};
              border-color: ${color.disbaledRed};
            }

            .MuiButton-root.Mui-disabled {
              color: ${color.white};
            }
          `}</style>

          <Formik
            initialValues={codeInitialVals}
            validationSchema={codeValidationSchema}
            onSubmit={handleSubmit}
          >
            {({ values }) => {
              return (
                <Form data-testid="onboarding-verify-mobile-number">
                  <GridContainer className="details-header">
                    <StepTitle activeStep="2" />

                    <GridItem className="title" xs={12} md={12}>
                      <>Verify your mobile number</>
                    </GridItem>
                    <GridItem xs={12}>
                      <p>
                        We’ve sent a 6-digit security code by text to{' '}
                        <b>{hashFormatMobileNumber(location.mobile)}</b>
                      </p>
                      <p>
                        <b>Enter the code below to verify your number.</b>
                      </p>
                    </GridItem>
                  </GridContainer>

                  <div className="code-container">
                    {phoneState.errorsCode !== null && (
                      <span className="error-text">This code is not valid</span>
                    )}
                  </div>

                  <span className="enter-code">
                    <b>Enter code*</b>
                  </span>

                  <div className="code-container">
                    <div className="wrap" onClick={handleClick}>
                      {CODE_LENGTH.map((v, index) => {
                        const selected = digits.length === index;
                        const filled =
                          digits.length === CODE_LENGTH.length && index === CODE_LENGTH.length - 1;
                        return (
                          <div className="display">
                            {digits[index]}
                            {(selected || filled) && focused && <div className="shadows" />}
                          </div>
                        );
                      })}

                      <input
                        autoFocus
                        id="code"
                        name="code"
                        maxLength={6}
                        value={values.code}
                        data-testid="onboarding.mobile.verify"
                        ref={inputRef}
                        onChange={handleChange}
                        onKeyUp={handleKeyUp}
                        onFocus={handleInputFocus}
                        onBlur={handleInputBlur}
                        className="input"
                        onKeyPress={(event: any) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        style={{
                          width: '20px',
                          top: '0px',
                          bottom: '0px',
                          left: `${selectedIndex * 30}px`,
                          opacity: hideInput ? 0 : 1
                        }}
                      />
                    </div>
                  </div>

                  <div className="button-container">
                    <GridItem xs={12} sm={4}>
                      <Button
                        data-testid="onboarding-personal-details-button"
                        className="submit-button"
                        type="submit"
                        onClick={handleSubmit}
                        disabled={!disabled}
                      >
                        Continue
                      </Button>
                    </GridItem>
                  </div>

                  <HelpContainer
                    text={'Not your number?'}
                    linkText={'Sign up with a different number'}
                    linkURL={'/mobile-number'}
                    target={false}
                  />
                  <HelpContainer />
                </Form>
              );
            }}
          </Formik>
        </>
      ) : (
        <>
          <div className="no-results-text">
            Something went wrong. <br />
            Please try logging in again.
          </div>
        </>
      )}
    </>
  );
};

export default MobileVerify;
