import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getStorageValue } from '../../utils/helperFunctions';

export interface AuthState {
  accessToken: string;
  continueToken: string;
  externalId: string;
  authState: string;
  consent: boolean;
  errors: null | string;
}

const retrievedObject = getStorageValue('storageData');

const initialState: AuthState = {
  accessToken: retrievedObject?.accessToken || '',
  continueToken: retrievedObject?.continueToken || '',
  externalId: retrievedObject?.externalId || '',
  authState: retrievedObject?.authState || '',
  consent: false,
  errors: null
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setErrors: (state, { payload }: PayloadAction<string>) => {
      state.errors = payload;
    },
    setAccessToken: (state, { payload }: PayloadAction<string>) => {
      state.accessToken = payload;
    },
    setContinueToken: (state, { payload }: PayloadAction<string>) => {
      state.continueToken = payload;
    },
    setAuthState: (state, { payload }: PayloadAction<string>) => {
      state.authState = payload;
    },
    setDataConsent: (state, { payload }: PayloadAction<boolean>) => {
      state.consent = payload;
    },
    setExternalId: (state, { payload }: PayloadAction<string>) => {
      state.externalId = payload;
    }
  }
});

// actions
export const {
  setErrors,
  setAccessToken,
  setExternalId,
  setAuthState,
  setDataConsent,
  setContinueToken
} = authSlice.actions;

export default authSlice.reducer;

export const authSelector = (state: { auth: AuthState }) => state.auth;
