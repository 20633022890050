// prettier-ignore
export const responsiveGrid = {
  sideMargins: {
    hiRes: 60,
    desktop: 60,
    tablet: 44,
    mobile: 16
  },
  columnSpacing: {
    hiRes: 24,
    desktop: 24,
    tablet: 24,
    mobile: 16
  }
};

/* PRE-EXISTING VARIABLES */
export const tileSize = {
  desktopWidth: 424,
  minHeight: 424,
  margin: 24,
  width: 348
};

export const spacing = {
  pageMargins: {
    hiRes: 60,
    desktop: 60,
    tablet: 24,
    mobile: 15
  },
  verticalStandard: 30,
  zero: {
    mobile: 0,
    tablet: 0,
    desktop: 0
  },
  small: {
    mobile: 24,
    tablet: 32,
    desktop: 40
  },
  medium: {
    mobile: 32,
    tablet: 40,
    desktop: 64
  },
  xLarge: {
    mobile: 40,
    tablet: 64,
    desktop: 88
  }
};
export interface Spacing {
  mobile?: number;
  tablet?: number;
  desktop?: number;
}

// prettier-ignore
/* eslint-disable no-unused-vars */
export enum color {
  brandPrimary = '#e10a0a',
  redHover = '#bf0808',
  greyHover = '#f5f5f5',
  grey01 = '#E5dEE6',
  grey02 = '#CCC',
  grey03 = '#979797',
  grey04 = '#e8e8e8',
  grey05 = '#f3f3f3',
  grey06 = '#e6e4e4',
  grey07 = '#6f6f6f',
  greyTransparent = 'rgba(0, 0, 0, 0.1)',
  greyTransparent2 = 'rgba(0, 0, 0, 0.5)',
  secondaryButtonBorder = '#E3E3E3',
  textHeader = '#1d1d1d',
  textStandard = '#1d1d1d',
  caption = '#808080',
  greyBackground = '#f7f7f7',
  pinkBackground = '#fff2f2',
  darkPinkBackground = '#fce6e6',
  lightPinkBackground = '#fef4f4',
  white = '#FFF',
  whiteRGB = '255, 255, 255',
  darkGrey = '#595959',
  authorGrey = '#999999',
  black = '#000',
  blackRGB = '0, 0, 0',
  blackUnderline = '#1a1a1a',
  successGreen = '#32996d',
  errorRed = '#f44336',
  focusedInputBackground = '#00000005',
  secondaryRedButtonHover = '#e2e2e2',
  rgbaRedHover = 'rgba(255, 255, 255, 0.5)',
  divider = 'rgb(132, 132, 132)',
  positivePointsGreen = '#097949',
  imageCreditOverlayBackground = 'rgba(0, 0, 0, 0.5)',
  imageCreditOverlay = '#ffffff',
  imageCredit = '#999999',
  lightGrey = '#d8d8d8',
  lighterGrey = '#737373',
  lighterGreen = '#ddebdf',
  secondaryDarkPurple = '#5c2244',
  disbaledRed = '#f9cece',
}

export const layout = {
  copyWidths: {
    tablet: 534,
    desktop: 648
  },
  heroWidth: {
    tablet: 722,
    desktop: 872
  }
};

export enum FontFamilies {
  utopiaStd = 'utopia-std, serif',
  barlow = 'Barlow, Arial, Helvetica, sans-serif'
}

export const squareImageSizes = {
  mobile: '256px',
  tablet: '352px',
  desktop: '424px'
};

export enum ZIndex {
  carouselBoxShadow = 1,
  carouselButton = 2,
  Banner = 10,
  Modal = 11,
  Menu = 12,
  UserMenu = 13
}

export const toastBgColors: { [keys: string]: string } = {
  error: '#fff6f5',
  warning: '#fffaf5',
  success: '#f7fff8',
  information: '#f3f3f3'
};

export const toastColors: {
  [keys: string]: { title: string; description: string };
} = {
  error: { title: '#9f0f10', description: '#5c1515' },
  warning: { title: '#af6d15', description: '#654318' },
  success: { title: '#2a664c', description: '#244d3b' },
  information: { title: '#1d1d1d', description: '#1d1d1d' }
};

const mobileMin = 0;
const mobileLargeMin = 420;
const tabletMin = 576;
const tabletLargeMin = 768;
const desktopMin = 992;
const hiResDesktopMin = 1440;

const mobileMax = tabletMin - 1;
const tabletMax = desktopMin - 1;
const desktopMax = hiResDesktopMin - 1;

export const breakpoints = {
  mobileMin,
  tabletMin,
  tabletLargeMin,
  desktopMin,
  hiResDesktopMin
};

export const media = {
  tablet: `(min-width: ${tabletMin}px) and (max-width: ${tabletMax}px)`,
  desktop: `(min-width: ${desktopMin}px) and (max-width: ${desktopMax}px)`,
  hiResDesktop: `(min-width: ${hiResDesktopMin}px)`,
  mobileAndLower: `(max-width: ${mobileMax}px)`,
  mobileLargeAndHigher: `(min-width: ${mobileLargeMin}px)`,
  tabletAndLower: `(max-width: ${tabletMax}px)`,
  tabletLargeAndLower: `(max-width: ${tabletLargeMin - 1}px)`,
  tabletAndHigher: `(min-width: ${tabletMin}px)`,
  tabletLargeAndHigher: `(min-width: ${tabletLargeMin}px)`,
  desktopAndHigher: `(min-width: ${desktopMin}px)`,
  retinaDisplay: '(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)'
};

export declare const RedAppTheme: import('@material-ui/core/styles').Theme;
