import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../../';
import { getProfileAPI, postProfileAPI } from './api';
import { Profile, PostableProfileItems } from './types';

export interface ProfileState {
  errors: any;
  profile: Profile | null;
  loading: boolean;
}

const initialState: ProfileState = {
  errors: null,
  profile: null,
  loading: false
};

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setErrors: (state, { payload }: PayloadAction<any>) => {
      state.errors = payload;
    },
    setProfile: (state, { payload }: PayloadAction<Partial<Profile>>) => {
      state.profile = payload;
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    }
  }
});

export const createProfile = (accessToken: string, payload: PostableProfileItems): AppThunk => {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const res = await postProfileAPI({ accessToken, payload });
      dispatch(setProfile(res));
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setErrors(error));
      dispatch(setLoading(false));
    }
  };
};

export const getProfile = (accessToken: string, externalId: string): AppThunk => {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const res = await getProfileAPI({ accessToken, externalId });
      dispatch(setProfile(res));
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setErrors(error));
      dispatch(setLoading(false));
    }
  };
};

// actions
export const { setErrors, setLoading, setProfile } = profileSlice.actions;

export default profileSlice.reducer;

export const profileSelector = (state: { profile: ProfileState }) => state.profile;
