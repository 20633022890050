import React, { ReactNode } from 'react';

import { layout, FontFamilies, media } from '../../styles';

// @ts-ignore
import topQuote from './pull-quote-top.svg';
// @ts-ignore
import bottomQuote from './pull-quote-bottom.svg';

interface ModalBodyProps {
  children: ReactNode;
  isBold?: boolean;
}

function ModalBody({ children, isBold }: ModalBodyProps) {
  return (
    <>
      <style jsx>{`
        .rich-text-copy :global(blockquote) {
          font-size: 40px;
          line-height: 48px;
          text-align: left;
          position: relative;
          margin: 88px 0px;
          ${isBold ? 'font-weight: bold;' : ''}
        }
        .rich-text-copy :global(blockquote > p) {
          max-width: ${layout.copyWidths.desktop}px;
          margin: auto;
          font-weight: bold;
          font-size: 32px;
          line-height: 40px;
          letter-spacing: 0;
          font-family: ${FontFamilies.utopiaStd};
        }
        .rich-text-copy :global(blockquote:before) {
          content: ' ';
          display: block;
          font-family: ${FontFamilies.utopiaStd};
          max-width: ${layout.copyWidths.tablet}px;
          margin: auto;
          background-image: url(${topQuote || ''});
          background-repeat: no-repeat;
          height: 61px;
          background-position: left;
          position: relative;
          bottom: 0px;
        }
        .rich-text-copy :global(blockquote:after) {
          content: ' ';
          display: block;
          font-family: ${FontFamilies.utopiaStd};
          max-width: ${layout.copyWidths.tablet}px;
          margin: auto;
          background-image: url(${bottomQuote || ''});
          background-repeat: no-repeat;
          height: 61px;
          background-position: right;
          position: relative;
          top: 0px;
        }

        @media ${media.desktopAndHigher} {
          .rich-text-copy :global(blockquote > p) {
            font-size: 40px;
            line-height: 48px;
          }
          .rich-text-copy :global(blockquote:before) {
            right: 100px;
            max-width: ${layout.copyWidths.desktop}px;
          }
          .rich-text-copy :global(blockquote:after) {
            left: 100px;
            max-width: ${layout.copyWidths.desktop}px;
          }
        }

        :global(.rich-text-copy p, .rich-text-copy h2, .rich-text-copy h3, .rich-text-copy
            ol, .rich-text-copy ul, .rich-text-copy hr) {
          max-width: ${layout.copyWidths.tablet}px;
          margin-left: auto;
          margin-right: auto;
        }

        :global(.rich-text-copy p) {
          font-size: 20px;
        }
        @media ${media.mobileAndLower} {
          :global(.rich-text-copy p) {
            font-size: 18px;
            line-height: 1.56;
          }
        }
        @media ${media.desktopAndHigher} {
          :global(.rich-text-copy > p, .rich-text-copy h2, .rich-text-copy h3, .rich-text-copy
              ol, .rich-text-copy ul, .rich-text-copy hr) {
            max-width: ${layout.copyWidths.desktop}px;
          }
        }
      `}</style>
      <div className="rich-text-copy">{children}</div>
    </>
  );
}

export default ModalBody;
