import React, { ReactNode } from 'react';

import { FontFamilies, color as globalColor, Spacing, media } from '../../styles';

interface ModalHeaderProps {
  children: ReactNode;
  verticalMargins?: Spacing;
}

function ModalHeader({ children, verticalMargins }: ModalHeaderProps) {
  const defaultMargin: Spacing = {
    mobile: 0,
    tablet: 0,
    desktop: 0
  };
  const margins: Spacing = {
    ...defaultMargin,
    ...verticalMargins
  };

  return (
    <>
      <style jsx>{`
        .header-three {
          text-align: center;
          font-size: 28px;
          font-weight: bold;
          font-family: ${FontFamilies.barlow};
          line-height: 1.17;
          letter-spacing: normal;
          color: ${globalColor.textStandard};
          margin: ${margins.mobile}px auto;
        }
        @media ${media.tabletAndHigher} {
          .header-three {
            font-size: 28px;
            line-height: 1.14;
            margin: ${margins.tablet}px auto;
          }
        }
        @media ${media.desktopAndHigher} {
          .header-three {
            letter-spacing: normal;
            margin: ${margins.desktop}px auto;
          }
        }
      `}</style>
      <h3 className="header-three">{children}</h3>
    </>
  );
}

export default ModalHeader;
