import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Init from '../pages/init/Init';
import Guard from '../pages/init/Guard';
import Oops from '../pages/error/Oops';
import NotFound from '../pages/error/NotFound';
import { PATHS } from './paths';
import PersonalDetails from '../pages/personal-details/PersonalDetails';
import PhoneNumber from '../pages/mobile-number/MobileNumber';
import MobileVerify from '../pages/mobile-verify/MobileVerify';
import Consent from '../pages/consent/Consent';
import Continue from '../pages/continue/Continue';

export const AppRoutes = () => {
  return (
    <Routes>
      <Route path={PATHS.INIT} element={<Init />} />
      <Route path={PATHS.GUARD} element={<Guard />} />
      <Route path={PATHS.MOBILE_NUMBER} element={<PhoneNumber />} />
      <Route path={PATHS.MOBILE_VERIFY} element={<MobileVerify />} />
      <Route path={PATHS.PERSONAL_DETAILS} element={<PersonalDetails />} />
      <Route path={PATHS.CONSENT} element={<Consent />} />
      <Route path={PATHS.CONTINUE} element={<Continue />} />
      <Route path={PATHS.OOPS} element={<Oops />} />
      <Route path="/*" element={<NotFound />} />
    </Routes>
  );
};
