import { ViewingRegion, ViewingRegionDisplayName } from './constants';

export const parseDigits = (input?: string) => (input?.match(/\d+/g) || []).join('');

export const getRegionDisplayName = (key: ViewingRegion): ViewingRegionDisplayName => {
  return ViewingRegionDisplayName[key];
};

export const addCountryCode = (mobile: string) => {
  return `+44${mobile.substring(1)}`;
};

export const hideCountryCode = (mobile: string) => {
  return `0${mobile.substring(3)}`;
};

export const getStorageValue = (key: string) => {
  const retrievedObject = window.localStorage.getItem(key);
  return retrievedObject !== null ? JSON.parse(retrievedObject) : {};
};

export const setStorageValue = (key: string, value: any) =>
  window.localStorage.setItem(key, JSON.stringify(value));
