import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { authSelector } from '../../features/auth/authSlice';
import { profileSelector, getProfile } from '../../features/profile/profileSlice';
import { PATHS } from '../../routes/paths';

import { REACT_APP_AUTH0_CONTINUE_URL } from '../../config';
import { LoadSpinner } from '../../components/LoadSpinner';

const Guard = () => {
  const profileState = useSelector(profileSelector);
  const { authState, accessToken, externalId, continueToken } = useSelector(authSelector);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (accessToken) {
      dispatch(getProfile(accessToken, externalId));
    }
  }, [accessToken, externalId, dispatch]);

  useEffect(() => {
    if (!profileState?.loading) {
      if (profileState?.profile?.complete) {
        window.location.href = `${REACT_APP_AUTH0_CONTINUE_URL}?state=${authState}&token=${continueToken}`;
      } else {
        navigate(PATHS.MOBILE_NUMBER);
      }
    }
  }, [profileState?.loading, profileState?.profile?.complete, authState, navigate, continueToken]);

  return (
    <>
      <LoadSpinner />
    </>
  );
};

export default Guard;
