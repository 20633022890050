import { Cookies } from 'react-cookie-consent';
import { REACT_APP_COOKIE_DOMAIN } from '../../config';

declare global {
  interface Window {
    dataLayer: Array<object>;
    msCrypto: Crypto;
  }
}

export const cookieName = 'user-cookie-preferences';

export enum CookiePreferenceId {
  MarketingAdvertising = 'marketing-advertising-cookies',
  Functional = 'functional-cookies',
  Analytics = 'analytics-cookies'
}

export type CookiePreferences = {
  [K in CookiePreferenceId]: boolean;
};

enum Trigger {
  PageLoad = 'page-load',
  AcceptAll = 'accept-all',
  SavePreferences = 'save-preferences'
}

interface DataLayerProperties {
  virtualPagePath?: string;
  virtualPageTitle?: string;
  trigger?: Trigger;
  cookie?: CookiePreferences;
  loggedIn?: boolean;
  tealiumId?: string;
  language?: string;
  viewingRegion?: string;
  campaign_id?: string; // SHOP-387 this has been requested as pascal case.
}

export const getDefaultCookiePreferences = (): CookiePreferences => ({
  [CookiePreferenceId.MarketingAdvertising]: false,
  [CookiePreferenceId.Functional]: false,
  [CookiePreferenceId.Analytics]: false
});

export const getMarketingAdvertisingPreferenceCookie = (): boolean | undefined => {
  const cookie = Cookies.get(cookieName);

  return cookie ? JSON.parse(cookie)['marketing-advertising-cookies'] : undefined;
};

export const getCookiePreferences = (): CookiePreferences | null => {
  const cookie = Cookies.get(cookieName);
  let savedPreferences = null;

  if (cookie) {
    try {
      savedPreferences = JSON.parse(cookie);
    } catch (e) {
      console.error(`Failed to parse cookie preferences cookie: ${(e as Error).message}`);
    }
  }

  // Make sure we initialise cookie preferences with safe defaults for BC when we add new preference keys
  return savedPreferences
    ? { ...getDefaultCookiePreferences(), ...(savedPreferences as any) }
    : null;
};

export const setDataLayer = ({
  virtualPagePath,
  virtualPageTitle,
  trigger,
  cookie,
  loggedIn,
  tealiumId,
  language,
  viewingRegion,
  campaign_id
}: DataLayerProperties): void => {
  window.dataLayer = window.dataLayer || [];
  const data = {
    event: 'virtualPageView',
    virtualPagePath: virtualPagePath || window.location.pathname,
    virtualPageTitle: virtualPageTitle || window.document.title,
    ...(trigger && { trigger }),
    ...(tealiumId && { tealiumId }),
    ...(cookie || (getCookiePreferences() ?? getDefaultCookiePreferences())),
    loggedIn: loggedIn || false,
    language: language || 'en', // TODO(SQ2-1945): language will need updating with non hardcoded value - when available.
    viewingRegion: viewingRegion,
    ...(campaign_id && { campaign_id })
  };
  window.dataLayer.push(data);
};

const persistCookiePreferences = (preferences: any, trigger: Trigger, loggedIn?: boolean): void => {
  Cookies.set(cookieName, preferences, {
    domain: REACT_APP_COOKIE_DOMAIN
  });
  setDataLayer({
    cookie: preferences,
    trigger,
    loggedIn
  });
};

export const setCookiePreferences = (preferences: CookiePreferences, loggedIn?: boolean): void => {
  persistCookiePreferences(preferences, Trigger.SavePreferences, loggedIn);
};

export const setAllCookiePreferences = (): void => {
  persistCookiePreferences(
    {
      [CookiePreferenceId.MarketingAdvertising]: true,
      [CookiePreferenceId.Functional]: true,
      [CookiePreferenceId.Analytics]: true
    },
    Trigger.AcceptAll
  );
};
